@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  background-color: rgb(88, 16, 16);
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
